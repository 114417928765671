// FAQ
export default [
  {
    id: 1,
    title: "Who is CareRide for?",
    text: "The nature of our platform is to connect passengers with special needs and companies offering accessible rides. That being said, CareRide is for people in need of wheelchair-accessible rides, caregivers or family members of those people, as well as NEMT-certified companies and drivers",
  },
  {
    id: 2,
    title: "What's the mission of CareRide?",
    text: "Our mission is to make this world more accessible and inclusive - one ride at a time. ",
  },
  {
    id: 3,
    title:
      "How is CareRide different from other services offering wheelchair-accessible rides?",
    text: "Unlike most of our competitors (traditional services offering non-emergency medical transportation), CareRide is an easy-to-use platform with transparent and competitive pricing based on demand.",
  },
  {
    id: 4,
    title: "How do I request a ride?",
    text: "Go to the platform, click to book a ride, and fill in all the details. The system will match you with the transportation company available near you and show you the price that's based on the market demand.",
  },
  {
    id: 5,
    title: "Can I book a ride for someone else?",
    text: 'Of course! In fact, we believe that giving someone a ride is just another way to express love and care. So, whether you\'re a caregiver or a family member, all you need is to select the "for someone else" option when requesting a ride.',
  },
  {
    id: 6,
    title: "What are the typical places people go to with CareRide?",
    text: "We believe NEMT should go far beyond getting people to and from medical appointments. Much like any other car-sharing or taxi service, CareRide can take you whenever you need. As for the most typical places, most clients order rides to grocery stores, parks, friend's houses, etc.",
  },
  {
    id: 7,
    title: "How do I become a partner?",
    text: "If you're a licensed driver or company, becoming our partner is super easy. Click the <a href='/partner' target='_blank'>\"Become a partner\"</a> tab on CareRide.com, fill in the form, and get your license attached. Once received, we will carefully review your application and get in touch with the next steps.",
  },
  {
    id: 8,
    title: "What is your refund policy?",
    text: "While you can cancel your CareRide trip at any time, our refund policy ensures that drivers always get paid for their time and effort. We issue full refunds for all trips canceled more than 24 hours in advance. As for the trips canceled within 24 hours of the scheduled time, a 50% cancelation fee applies. For trips canceled when our driver has already arrived or is en route to pick up a passenger, no refunds are possible.",
  },
];
